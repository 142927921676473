<template>
</template>

<script setup lang="ts">
import {useRouter} from "vue-router";
import {useRoute} from "vue-router";

definePageMeta({
  layout: "blank",
});

onMounted(() => {
  const router = useRouter();
  const route = useRoute()
  router.push(route.path)
})

</script>